import React from "react"
import { Form } from "react-bootstrap"
import { getIn } from "formik"

const { Control, Group, Label } = Form

export default function FormField({
  field: { value = "", ...field } = {},
  form: { touched = {}, errors = {} } = {},
  icon,
  groupProps = {},
  handleChange,
  hintText,
  label,
  controlId,
  groupClass = "",
  ...props
}) {
  // const showError = !!getIn(touched, field.name) && !!getIn(errors, field.name)
  const showError = !!getIn(errors, field.name)

  const onChange = e => {
    field.onChange(e)
    if (handleChange) {
      handleChange(e)
    }
  }

  return (
    <>
      <Group
        controlId={controlId || field.name}
        className={"inline-items " + groupClass}
      >
        {!!label && <Label className="form-label">{label}</Label>}

        <div class="custom-upload">
          <Control
            {...field}
            value={value || ""}
            {...props}
            isInvalid={showError}
            onChange={onChange}
            className={"custom-upload"}
          />
          <div class="custom-upload-box d-flex">
            <div class="upload-btn">
              <a href="" class="btn btn-purple btn-icon icon-right">
                Upload <i class="la la-cloud-upload"></i>
              </a>
            </div>
            <div class="upload-info">
              <span>File format must be</span>
              <span>JPEG, PNG or PDF, Minimal resolution 300dpi</span>
            </div>
          </div>
        </div>
        {!!hintText && (
          <Form.Text className="text-muted textarea-hint">{hintText}</Form.Text>
        )}
        {!!showError && (
          <Control.Feedback type="invalid" style={{ display: "block" }}>
            {getIn(errors, field.name)}
          </Control.Feedback>
        )}
      </Group>
    </>
  )
}
