import React from "react"
// import { Link } from "gatsby"

// import { IMAGES } from '../../constants';
import { PrismicRichText } from "./../shared"

const MainContent = ({ content, heading, className }) => {
  return (
    <>
      <section className={"job-contents p-top-20 p-bottom-65 " + className}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3>{heading[0] !== undefined && heading[0].text}</h3>
              <PrismicRichText data={content} />
            </div>
          </div>
          {/* {children} */}
        </div>
      </section>
    </>
  )
}

export default MainContent
