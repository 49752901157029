import React, { useState } from "react"
import { Formik, Form, Field } from "formik"

import FormField from "./form-field"
import FormFieldFile from "./form-field-file"
import FormFieldSubmit from "./form-field-submit"
import FormFieldRadio from "./form-field-radio"
import FormFieldRadioUpdate from "./form-field-radio-update"
import FormFieldDropdown from "./form-field-dropdown"
import FormFieldCheckbox from "./form-field-checkbox"

import { Button } from "react-bootstrap"

export default function LoginForm({
  formFields = [],
  buttonLabel = "Submit",
  submitAction = () => {},
  backButtonLabel = "Back",
  backAction = () => {},
  hideBackButton = false,
  ...props
}) {
  const [formFiles, setFormFiles] = useState({})
  const [formSubmitting, setFormSubmitting] = useState(0)
  const onSubmit = async (values, { setSubmitting }) => {
    try {
      setFormSubmitting(1)
      let formValues = values
      formValues.files = formFiles
      await submitAction(formValues)
      setFormSubmitting(2)
    } catch (error) {
      console.log("Error ", error)
      setFormSubmitting(3)
    } finally {
      // setFormSubmitting(0)
      // setSubmitting(false)
    }
  }

  const filChangeHandler = e => {
    // console.log('changed vakye ', value.files[0]);
    e.name = e.target.files[0]
    setFormFiles({
      ...formFiles,
      [e.target.name]: e.target.files[0],
    })
  }

  return (
    <div className="form-ik-handler">
      <header className="text-center title">
        <h2>{props.title}</h2>
      </header>
      <Formik {...props} onSubmit={onSubmit}>
        {({ isValid, isSubmitting, errors }) => (
          <Form noValidate className="form-ik">
            {formFields.map((formField, fieldsIndex) => (
              <div key={"field-" + fieldsIndex} className="forms-group-section">
                <h4>{formField.heading}</h4>
                {formField.fields.map((field, i) => (
                  <>
                    {field.type === "RADIO" && (
                      <div
                        className={
                          field.class
                            ? field.class + " inline-items"
                            : "col-sm-6 inline-items"
                        }
                        key={"form-field-" + i}
                      >
                        {/* <FormFieldRadio  key={i} component={FormField} {...field}/> */}
                        <Field
                          key={i}
                          component={FormFieldRadioUpdate}
                          {...field}
                        />
                      </div>
                    )}
                    {field.type === "TEXT" && (
                      <Field
                        key={"form-field-" + i}
                        groupClass={field.class ? field.class : "col-sm-6"}
                        key={i}
                        component={FormField}
                        {...field}
                      />
                    )}
                    {field.type === "NUMBER" && (
                      <Field
                        key={"form-field-" + i}
                        groupClass={field.class ? field.class : "col-sm-6"}
                        key={i}
                        component={FormField}
                        {...field}
                      />
                    )}
                    {field.type === "DROPDOWN" && (
                      <div
                        className={
                          field.class
                            ? field.class + " col-sm-6 inline-items "
                            : "col-sm-6 inline-items "
                        }
                        key={"form-field-" + i}
                      >
                        <FormFieldDropdown
                          key={"form-field-" + i}
                          groupClass={field.class ? field.class : "col-sm-6"}
                          key={i}
                          component={FormField}
                          {...field}
                        />
                      </div>
                    )}
                    {field.type === "CHECKBOX" && (
                      <>
                        {/* <div className={field.class ? field.class+' inline-items': 'col-sm-6 inline-items '} key={'form-field-'+i}>
                                          <FormFieldCheckbox key={'form-field-'+i} key={i} component={FormField} {...field} />
                                      </div> */}
                        <div
                          className={
                            field.class
                              ? field.class + " inline-items"
                              : "col-sm-6 inline-items "
                          }
                          key={"form-field-" + i}
                        >
                          <Field
                            type="checkbox"
                            checked={field.value}
                            key={"form-field-" + i}
                            key={i}
                            component={FormFieldCheckbox}
                            {...field}
                          />
                        </div>
                      </>
                    )}
                    {field.type === "FILE" && (
                      <>
                        <Field
                          key={"form-field-" + i}
                          handleChange={filChangeHandler}
                          groupClass={field.class ? field.class : "col-sm-12"}
                          key={i}
                          component={FormFieldFile}
                          {...field}
                        />
                      </>
                    )}
                    {field.type === "DATE" && (
                      <Field
                        key={"form-field-" + i}
                        groupClass={field.class ? field.class : "col-sm-6"}
                        key={i}
                        component={FormField}
                        {...field}
                      />
                    )}
                    {field.type === "HTML_DATA" && (
                      <div
                        dangerouslySetInnerHTML={{ __html: field.text }}
                      ></div>
                    )}
                  </>
                ))}
                {!!errors && !!errors.submitError && (
                  <div className="text-danger">{errors.submitError}</div>
                )}
              </div>
            ))}

            <FormFieldSubmit
              title={buttonLabel}
              disabled={!isValid || isSubmitting || formSubmitting === 1}
              className={props.submitStyle}
            />
            <div className="submit-message-container">
              {formSubmitting === 1 && (
                <i
                  style={{
                    fontSize: "2rem",
                    marginRight: "2rem",
                    marginTop: "0.4rem",
                  }}
                  class="fa fa-circle-notch fa-spin"
                ></i>
              )}
              {formSubmitting === 2 && (
                <div class="alert alert-success" role="alert">
                  Form Submit, sucessfull.
                </div>
              )}
              {formSubmitting === 3 && (
                <div class="alert alert-danger" role="alert">
                  Failed to submit form.
                </div>
              )}
            </div>

            {hideBackButton === false && (
              <Button
                className={"btn btn-info"}
                onClick={backAction}
                disabled={isSubmitting || formSubmitting === 1}
              >
                {backButtonLabel}
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}
