// import React from 'react';

// import { Field } from "formik";

// export default function Checkbox({ id, name, className, label }) {
//   return (
//     <div className="row container">
//       <div className="" style={{width: '25px'}}>
//         <Field
//             name={name}
//             render={({ field, form }) => {
//             return (
//                 <input
//                 type="checkbox"
//                 id={id}
//                 className={className}
//                 checked={field.value}
//                 {...field}
//                 />
//             );
//             }}
//         />
//       </div>
//         <div className="col-sm-10" style={{padding: '0px'}}>
//             <label htmlFor={name}>{label}</label>
//         </div>

//     </div>
//   );
// }

import React from "react"
import { Form } from "react-bootstrap"
import { getIn } from "formik"
import reducer from "../../reducers"

const { Control, Group, Label } = Form

export default function FormField({
  field: { value = "", ...field } = {},
  form: { touched = {}, errors = {} } = {},
  icon,
  groupProps = {},
  handleChange,
  hintText,
  label,
  controlId,
  groupClass = "",
  radioButtonInline = false,
  name,
  options,
  labelBold = false,
  ...props
}) {
  // const showError = !!getIn(touched, field.name) && !!getIn(errors, field.name)
  const showError = !!getIn(errors, field.name)
  const onChange = e => {
    field.onChange(e)
    if (handleChange) {
      handleChange(e)
    }
  }
  // console.log('field name ', field)
  return (
    <>
      <Group
        controlId={controlId || field.name}
        className={"inline-items " + groupClass}
        style={{ marginBottom: "0px" }}
      >
        {!!label && (
          <Label
            style={{ display: "table-cell", paddingBottom: "1rem" }}
            className="form-label"
          >
            {labelBold === true && <strong>{label}</strong>}
            {labelBold === false && label}
          </Label>
        )}

        <div>
          {options.map((item, index) => (
            <div
              style={{
                display: radioButtonInline === true ? "inline-block" : "block",
                marginBottom: "1rem",
                marginRight: "1rem",
              }}
              className="custom-control custom-radio custom-radio-two"
            >
              <input
                type="radio"
                id={field.name + index}
                {...field}
                value={item.value}
                {...props}
                isInvalid={showError}
                onChange={onChange}
                className="checkbox"
                // style={{marginRight: '1rem'}}
                className="custom-control-input"
              />
              <label
                htmlFor={field.name + index}
                className="custom-control-label"
              >
                {item.text}
              </label>
            </div>
          ))}
        </div>

        {!!showError && (
          <label className="custom-error-label">
            {getIn(errors, field.name)}
          </label>
        )}
      </Group>
    </>
  )
}
