import React from "react"
import { Table as BTable } from "react-bootstrap"
import { IMAGES } from "../../constants"

/**
 * This component accepts just two parameters
 *
 * @param { header } array of strings
 * @param { body } array of array of strings
 */

const Table = ({ header = [], body = [], pageTitle = '' }) => {
  const hideMargin = ['Forex', 'Bullion', 'Indices', 'Commodities Energy'].indexOf(pageTitle) > -1;
  return (
    <section className="job-contents p-top-20 p-bottom-65">
      <div className="container">
        <div className="tables-responsive text-center">
          <BTable responsive="md">
            <thead className="table-secondary table-secondary--darken">
              <tr>
                {header.map((item, index) => (
                  <th
                    key={index}
                    className={
                      header.length < 3 ? (index === 0 ? "text-left" : "") : ""
                    }
                    style={{display: (hideMargin && index == 2) ? 'none': ''}}
                  >
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {body.map((row, index) => (
                <tr key={index}>
                  {row.map((item, index) => (
                    <td
                      key={index}
                      className={
                        header.length < 3
                          ? index === 0
                            ? "text-left"
                            : ""
                          : ""
                      }
                      style={{display: (hideMargin && index == 2) ? 'none': ''}}
                    >
                      {(item.text !== "" && item.text) ||
                        (item.file_url !== "" && (
                          <a
                            href={item.file_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={IMAGES.PDF_FILE_ICON} />
                          </a>
                        ))}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </BTable>
        </div>
      </div>
    </section>
  )
}

export default Table
