const locales = {
  "en-gb": {
    opening_account: "Opening Account",
    download: "Download",
    proceed: "Proceed",
    full_name: "Full Name",
    email: "Email",
    amount: "Amount",
    account: "Account",
    deposit: "Deposit",
  },
  "ar-ae": {
    opening_account: "فتح الحسابات",
    download: "تحميل",
    proceed: "متابعة",
    full_name: "الاسم",
    email: "البريد الالكتروني",
    amount: "المبلغ",
    account: "الحساب",
    deposit: "ايداع",
  },
}

export default function trans(key, language) {
  return locales[language][key] || key
}
