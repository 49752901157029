import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  query DepositWithNetellerQuery {
    allPrismicPaymentGateway(
      filter: {
        data: {
          form_heading: {}
          form_name: {
            elemMatch: { text: { eq: "Deposit Using Neteller Page" } }
          }
        }
      }
    ) {
      nodes {
        data {
          banner_image {
            url
          }
          form_heading {
            text
          }
          form_name {
            text
          }
          submit_button_text {
            text
          }
        }
      }
    }
  }
`

const dataResolver = response => {
  const { allPrismicPaymentGateway } = response
  return allPrismicPaymentGateway.nodes[0].data
}

const useData = lang => {
  const response = useStaticQuery(query)
  return dataResolver(response)
}

export default useData
