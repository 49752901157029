const fields = {
  account_information_account_currency: "Account Information: Account Currency",
  account_information_any_person_on_behalf:
    "Account Information: Will any other person trade on your behalf",
  account_information_how_hear_about_us:
    "Account Information: How did you hear about us ? ",

  company_details_registered_name: "Company Detail: Registered Name",
  company_details_type_of_business: "Company Detail: Type of Buisness",
  company_details_trading_name: "Company Detail: Trading Name",
  company_details_registered_number: "Company Detail: Registered Number",
  company_details_registered_address: "Company Detail: Registered Address",
  company_details_physical_address: "Company Detail: Physical Address",
  company_details_correspondence_address:
    "Company Detail: Correspondence Address",
  company_details_copmany_email_address: "Company Detail: Email Address",
  company_details_copmany_telephone_number: "Company Detail: Telephone Number",
  company_details_company_registered_with_fca:
    "Company Detail: Is company registered with FCA or other regulator ?",
  company_details_company_listed_on_any_stock_exchange:
    "Company Detail: Is company or its parent company, listed on any stock Exchange",

  contact_details_title:
    "Contact Details of Principle Director or Authorized Person: Title",
  contact_details_first_name:
    "Contact Details of Principle Director or Authorized Person: First Name",
  contact_details_middle_name:
    "Contact Details of Principle Director or Authorized Person: Middle Name",
  contact_details_surname:
    "Contact Details of Principle Director or Authorized Person: Surname",
  contact_details_gender:
    "Contact Details of Principle Director or Authorized Person: Gender",
  contact_details_dob:
    "Contact Details of Principle Director or Authorized Person: Date of Birth",
  contact_detail_nationality:
    "Contact Details of Principle Director or Authorized Person: Ntionality",
  contact_details_email_address:
    "Contact Details of Principle Director or Authorized Person: Email Address",

  contact_details_principal_trader_first_name:
    "Contact Detail Principal Trader: First Name",
  contact_details_principal_trader_middle_name:
    "Contact Detail Principal Trader: Middle Name",
  contact_details_principal_trader_surname:
    "Contact Detail Principal Trader: Surname",
  contact_details_principal_trader_gender:
    "Contact Detail Principal Trader: Gender",
  contact_details_principal_trader_dob:
    "Contact Detail Principal Trader: Date of Birth",
  contact_details_principal_trader_email_address:
    "Contact Detail Principal Trader: Email Address",
  contact_details_principal_trader_telephone_number:
    "Contact Detail Principal Trader: Telephone Number",
  contact_details_principal_trader_passport_number:
    "Contact Detail Principal Trader: Passport Number",
  contact_detail_nationality: "Contact Detail Principal Trader: Nationality",
  contact_detail_principal_trader_nationality:
    "Contact Detail Principal Trader: Contact Details",

  financial_information_company_net_turnover:
    "Financial Information: What is the Company’s net turnover ",
  financial_information_company_have_balance_sheet_more_than_12_5_mio:
    "Financial Information: Balance sheet total of more than EUR 12.5mio",
  financial_information_company_have_own_funds_of_eur_2mio:
    "Financial Information: Own funds of EUR 2mio",
  financial_information_company_have_number_of_employees_more_than_250:
    "Financial Information: An average number of employees of more than 250?",
  financial_information_company_have_expected_initial_deposit:
    "Financial Information: Expected initial deposit size",

  trading_experience_traded_cfds_or_fx_before:
    "Trading Experience: Have you traded CFDs or margined FX using a demo account",
  trading_experience_forex: "Trading Experience: Forex",
  trading_experience_futures_cfds_spreadbets:
    "Trading Experience: Future CFDs or Spreadbets",
  trading_experience_shares_bonds: "Trading Experience: Sharees or Bonds",

  we_understand_and_accept:
    "Client Agreement: We understand & accept aggrement",
  we_have_read_terms: "Client Agreement: We have react Terms ",
  we_have_access_to_internet:
    "Client Agreement: We have access to internet & consent",
  we_under_stand_that_cfds:
    "Client Agreement: we understand that CFDs & margined FX are leverged products",
  we_confirm_information:
    "Client Agreement: We confirm that the information represented here is accurent",

  market_knowledge_directors_understand_risk_of_trading:
    "Market Knowledge: Do the directors and/or principal contact person understand the nature and risk of trading margined/leveraged products?",
  market_knowledge_understand_you_can_lost_initial_deposit:
    "Market Knowledge: Do the directors and/or principal contact person understand that you can lose more than your initial deposit or margin payment?",
  market_knowledge_must_maintain_minimum_level_margin:
    "Market Knowledge: Are you aware that the Company must maintain a minimum level of margin in the account to cover any margin requirements ?",
  market_knowledge_understand_counterpart_position_with_us:
    "Market Knowledge: Do the directors and/or principal contact person understand that we are the Company’s counterparty and that your positions are taken not with an exchange?",
  market_knowledge_understand_volatile_market_may_impact:
    "Market Knowledge: Do the directors and/or principal contact understand that volatile market conditions may impact our ability to generate prices or to execute your orders?",
  market_knowledge_company_investment_objective:
    "Market Knowledge: What are the Company’s investment objectives",
}

const getLabel = key => {
  const text = fields[key] ? fields[key] : key
  return text
}
export default getLabel
