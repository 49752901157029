import React from "react"
// import { Link } from "gatsby"
import { Link } from "gatsby"
import { FormLabel } from "react-bootstrap"

import Breadcrumb from "react-bootstrap/Breadcrumb"

const Banner = ({ heading = [], banner_image = {} }) => {
  let p_heading = ""
  if (heading && heading[0]) p_heading = heading[0].text
  return (
    <>
      <section
        className="topBanner breadCrumbBanner"
        style={{ height: "19rem" }}
      >
        <div
          className="bg_image_holder"
          style={{ backgroundImage: `url(${banner_image.url})`, opacity: 1 }}
        >
          <img src={banner_image.url} alt="" />
        </div>
        <div className="container content_above">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumb_wrapper d-flex flex-column align-items-center">
                <h4 className="page_title">{p_heading}</h4>

                <Breadcrumb>
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Home</Link>
                  </li>
                  <Breadcrumb.Item active>{p_heading}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Banner
