import React from "react"
import { Field } from "formik"
import { Form } from "react-bootstrap"

const { Control, Group, Label } = Form
const RadioButton = props => {
  const { label, name, options, required, ...rest } = props
  return (
    <div>
      <label style={{ display: "block" }} htmlFor={name}>
        {label}
      </label>
      <Field as="select" name={name}>
        {options.map(option => (
          <option value={option.value}>{option.text}</option>
        ))}
      </Field>
    </div>
  )
}

export default RadioButton
