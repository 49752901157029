// import React from 'react';

// import { Field } from "formik";

// export default function Checkbox({ id, name, className, label }) {
//   return (
//     <div className="row container">
//       <div className="" style={{width: '25px'}}>
//         <Field
//             name={name}
//             render={({ field, form }) => {
//             return (
//                 <input
//                 type="checkbox"
//                 id={id}
//                 className={className}
//                 checked={field.value}
//                 {...field}
//                 />
//             );
//             }}
//         />
//       </div>
//         <div className="col-sm-10" style={{padding: '0px'}}>
//             <label htmlFor={name}>{label}</label>
//         </div>

//     </div>
//   );
// }

import React from "react"
import { Form } from "react-bootstrap"
import { getIn } from "formik"
import reducer from "../../reducers"

const { Control, Group, Label } = Form

export default function FormField({
  field: { value = "", ...field } = {},
  form: { touched = {}, errors = {} } = {},
  icon,
  groupProps = {},
  handleChange,
  hintText,
  label,
  controlId,
  groupClass = "",
  ...props
}) {
  // const showError = !!getIn(touched, field.name) && !!getIn(errors, field.name)
  const showError = !!getIn(errors, field.name)
  const onChange = e => {
    field.onChange(e)
    if (handleChange) {
      handleChange(e)
    }
  }

  return (
    <>
      <Group
        controlId={controlId || field.name}
        className={"inline-items " + groupClass}
        style={{ marginBottom: "0px" }}
      >
        <label className="checkbox-container">
          <input
            type="checkbox"
            {...field}
            id={field.name}
            type={"checkbox"}
            value={value || ""}
            {...props}
            isInvalid={showError}
            onChange={onChange}
            className="checkbox"
            style={{ marginRight: "1rem" }}
          />
          <span class="checkmark"></span>
          {!!label && (
            <Label style={{}} className="form-label" htmlFor={field.name}>
              {label}
            </Label>
          )}
        </label>
        {!!showError && (
          <label className="custom-error-label">
            {getIn(errors, field.name)}
          </label>
        )}
      </Group>
    </>
  )
}
