import Api from "./../api/index"
const apiService = new Api()

export const liveApplicationSubmit = async data => {
  try {
    const { result, message } = await apiService.postFilesForm(
      "api/common/live-application",
      data
    )
    return {
      result,
      message,
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const corporateApplicationSubmit = async data => {
  try {
    const { result, message } = await apiService.postFilesForm(
      "api/common/corporate-application",
      data
    )
    return {
      result,
      message,
    }
  } catch (error) {
    return Promise.reject(error)
  }
}
