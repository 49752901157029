import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  query AccountTypesQuery {
    allPrismicAccountTypes(
      filter: {
        data: {
          page_name: { elemMatch: { text: { eq: "Account Types Page" } } }
        }
      }
    ) {
      nodes {
        data {
          account_types {
            account_cost {
              text
            }
            account_cost_label {
              text
            }
            account_features {
              text
              type
            }
            account_title {
              text
            }
            button_color
            button_link {
              text
            }
            button_name {
              text
            }
            account_message {
              text
            }
          }
          banner_image {
            url
          }
          page_heading {
            text
          }
        }
        lang
      }
    }
  }
`

const dataResolver = response => {
  const { allPrismicAccountTypes } = response
  return allPrismicAccountTypes.nodes[0].data
}

const useData = lang => {
  const response = useStaticQuery(query)
  return dataResolver(response, lang)
}

export default useData
