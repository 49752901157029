import React from "react"
// import { Link } from "gatsby"

import { Card } from "react-bootstrap"

const Stats = ({ heading = [] }) => {
  return (
    <>
      <section className="quickStats quickStatsText">
        <div className="information-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Card>
                  <Card.Header as="h5">{heading[0].text}</Card.Header>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <div style={{ clear: "both" }}></div>
      </section>
    </>
  )
}

export default Stats
