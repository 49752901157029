import React, { useState } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

import FormIkHandler from "./formik-handler"
import { corporateApplicationSubmit } from "../../actions"
import { COUNTRIES, getLabelCorporateAccount } from "../../constants"

const CorporateAccountForm = () => {
  const formStates = ["personal", "financial", "experience", "acknowledgment"]
  const [formState, setFormState] = useState(formStates[0])

  const [form1Data, setForm1Data] = useState({})
  const [form2Data, setForm2Data] = useState({})
  const [form3Data, setForm3Data] = useState({})
  const [form4Data, setForm4Data] = useState({})
  const scrollUp = () => {
    const element = document.getElementById("stepper-tabs-header")
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    })
    console.log("ELELEMENT", element)
  }
  const personalForm = {
    formFields: [
      {
        heading: "Account Information",
        fields: [
          {
            name: "account_information_account_currency",
            type: "RADIO",
            label: "Please select your account currency:",
            options: [
              { value: "usd", text: "USD" },
              { value: "eur", text: "EUR" },
              { value: "gbp", text: "GBP" },
            ],
            radioButtonInline: true,
          },
          {
            name: "account_information_any_person_on_behalf",
            type: "RADIO",
            label: "Will any other person trade on your behalf?",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            radioButtonInline: true,
          },
          {
            name: "account_information_how_hear_about_us",
            label: "How did you hear about us?",
            type: "DROPDOWN",
            options: [
              { value: "seminar", text: "Seminar" },
              { value: "online ad", text: "Online Ad" },
              { value: "magazine", text: "Magazine" },
              { value: "search engine", text: "Search Engine" },
              { value: "newspaper", text: "Newspaper" },
              { value: "introducer", text: "Introducer" },
              { value: "friend", text: "Friend" },
              { value: "other", text: "Other" },
            ],
            class: "col-sm-12",
          },
        ],
      },
      {
        heading: "Company Details",
        fields: [
          {
            name: "company_details_registered_name",
            label: "Registered name of the company",
            required: true,
            type: "TEXT",
          },
          {
            name: "company_details_type_of_company",
            label: "Type of company",
            required: true,
            type: "DROPDOWN",
            options: [
              { value: "private", text: "Private" },
              { value: "public", text: "Public" },
            ],
            // class: 'col-sm-12',
            required: true,
          },
          {
            name: "company_details_type_of_business",
            type: "TEXT",
            label: "Type of business",
          },
          {
            name: "company_details_trading_name",
            type: "TEXT",
            label: "Trading name (if different to above)",
          },
          ,
          {
            name: "company_details_registered_number",
            type: "NUMBER",
            label: "Registered number",
          },
          {
            name: "company_details_registered_address",
            type: "TEXT",
            label: "Registered Address",
          },
          {
            name: "company_details_physical_address",
            type: "TEXT",
            label: "Physical address (if different to above):",
          },
          {
            name: "company_details_correspondence_address",
            type: "TEXT",
            label: "Correspondence address (if different to above):",
          },
          {
            name: "company_details_copmany_email_address",
            type: "TEXT",
            label: "Company Email Address",
          },
          {
            name: "company_details_copmany_telephone_number",
            type: "TEXT",
            label: "Company telephone number:",
          },

          {
            name: "company_details_company_registered_with_fca",
            type: "RADIO",
            label:
              "Is the Company registered with the FCA or other regulator ?",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            // required: true,
            radioButtonInline: true,
            class: "col-sm-12 ",
          },
          {
            name: "company_details_company_listed_on_any_stock_exchange",
            type: "RADIO",
            label:
              "Is the Company or its parent company, listed on any Stock Exchange",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            // required: true,
            radioButtonInline: true,
          },
        ],
      },
      {
        heading:
          "Contact Details of The Principal Director or Authorised Person",
        fields: [
          {
            name: "contact_details_title",
            label: "Title",
            required: true,
            type: "DROPDOWN",
            options: [
              { value: "mr", text: "Mr" },
              { value: "ms", text: "Ms" },
            ],
            required: true,
          },
          {
            name: "contact_details_first_name",
            type: "TEXT",
            label: "First Name",
            required: true,
          },
          {
            name: "contact_details_middle_name",
            type: "TEXT",
            label: "Middle name (s)",
          },
          {
            name: "contact_details_surname",
            type: "TEXT",
            label: "Surname",
            required: true,
          },
          {
            name: "contact_details_gender",
            type: "RADIO",
            label: "Gender",
            options: [
              { value: "male", text: "Male" },
              { value: "female", text: "Female" },
            ],
            required: true,
            radioButtonInline: true,
          },
          {
            name: "contact_details_dob",
            type: "DATE",
            label: "Date of Birth",
            required: true,
          },
          {
            name: "contact_detail_nationality",
            label: "Nationality",
            type: "DROPDOWN",
            options: [{ text: "Select country", value: "" }, ...COUNTRIES],
          },
          {
            name: "contact_details_email_address",
            type: "TEXT",
            label: "Email Address",
            required: true,
          },
          {
            name: "contact_details_telephone_number",
            type: "Number",
            label: "Telephone Number",
          },
        ],
      },
      {
        heading: "Contact Details of The Principal Trader",
        fields: [
          {
            name: "contact_details_principal_trader_title",
            label: "Title",
            required: true,
            type: "DROPDOWN",
            options: [
              { value: "mr", text: "Mr" },
              { value: "ms", text: "Ms" },
            ],
            class: "col-sm-12",
            required: true,
          },
          {
            name: "contact_details_principal_trader_first_name",
            type: "TEXT",
            label: "First Name",
            required: true,
          },
          {
            name: "contact_details_principal_trader_middle_name",
            type: "TEXT",
            label: "Middle name (s)",
          },
          {
            name: "contact_details_principal_trader_surname",
            type: "TEXT",
            label: "Surname",
            required: true,
          },
          {
            name: "contact_details_principal_trader_gender",
            type: "RADIO",
            label: "Gender",
            options: [
              { value: "male", text: "Male" },
              { value: "female", text: "Female" },
            ],
            required: true,
            radioButtonInline: true,
          },
          {
            name: "contact_details_principal_trader_dob",
            type: "DATE",
            label: "Date of Birth",
            required: true,
          },
          {
            name: "contact_detail_principal_trader_nationality",
            label: "Nationality",
            type: "DROPDOWN",
            options: [{ text: "Select country", value: "" }, ...COUNTRIES],
          },
          {
            name: "contact_details_principal_trader_email_address",
            type: "TEXT",
            label: "Email Address",
            required: true,
          },
          {
            name: "contact_details_principal_trader_telephone_number",
            type: "NUMBER",
            label: "Telephone Number",
          },
          {
            name: "contact_details_principal_trader_passport_number",
            type: "TEXT",
            label: "Passport Number",
            required: true,
          },
        ],
      },
    ],
    submitHandler: values => {
      setForm1Data(values)
      setFormState(formStates[1])
      scrollUp()
    },
    validationSchema: Yup.object().shape({
      company_details_registered_name: Yup.string().required("Required"),
      // "company_details_type_of_company": Yup.string()
      //     .required('Required'),
      company_details_trading_name: Yup.string().required("Required"),
      company_details_registered_number: Yup.string().required("Required"),
      company_details_copmany_email_address: Yup.string().required("Required"),
      company_details_copmany_telephone_number: Yup.string().required(
        "Required"
      ),

      // "contact_details_title": Yup.string()
      //     .required('Required'),
      contact_details_first_name: Yup.string().required("Required"),
      contact_details_surname: Yup.string().required("Required"),
      contact_details_gender: Yup.string().required("Required"),
      contact_details_dob: Yup.string().required("Required"),
      // "contact_detail_nationality": Yup.string()
      //     .required('Required'),
      contact_details_email_address: Yup.string().required("Required"),

      // "contact_details_principal_trader_title": Yup.string()
      //     .required('Required'),
      contact_details_principal_trader_first_name: Yup.string().required(
        "Required"
      ),
      contact_details_principal_trader_surname: Yup.string().required(
        "Required"
      ),
      contact_details_principal_trader_gender: Yup.string().required(
        "Required"
      ),
      contact_details_principal_trader_dob: Yup.string().required("Required"),
      // "contact_detail_principal_trader_nationality": Yup.string()
      //     .required('Required'),
      contact_details_principal_trader_email_address: Yup.string().required(
        "Required"
      ),
    }),
    initialValues: {
      ...form1Data,
    },
  }

  const financialForm = {
    formFields: [
      {
        heading: "Financial Information",
        fields: [
          {
            name: "financial_information_company_net_turnover",
            type: "RADIO",
            label:
              "What is the Company’s net turnover (in EUR (approximate USD value in brackets)):(please tick one)",
            options: [
              {
                value: "more than 40mio (45mio )",
                text: "more than 40mio (45mio )",
              },
              {
                value: "between 25mio (28mio) and 40mio (45mio )",
                text: "between 25mio (28mio) and 40mio (45mio )",
              },
              {
                value: "less than 25mio (28mio)",
                text: "less than 25mio (28mio)",
              },
            ],
            class: "col-sm-12",
            labelBold: true,
          },
          {
            type: "HTML_DATA",
            text: "<strong>Does the Company have:</strong><br/><br/>",
          },
          {
            name:
              "financial_information_company_have_balance_sheet_more_than_12_5_mio",
            type: "RADIO",
            label:
              "Balance sheet total of more than EUR 12.5mio (approx. USD 14mio)",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            radioButtonInline: true,
          },
          {
            name: "financial_information_company_have_own_funds_of_eur_2mio",
            type: "RADIO",
            label: "Own funds of EUR 2mio (approx. USD 2.5mio)",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            radioButtonInline: true,
          },
          {
            name:
              "financial_information_company_have_number_of_employees_more_than_250",
            type: "RADIO",
            label: "An average number of employees of more than 250?",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            radioButtonInline: true,
          },
          {
            name: "financial_information_company_have_expected_initial_deposit",
            type: "TEXT",
            label: "Expected initial deposit size",
          },
        ],
      },
    ],
    submitHandler: values => {
      setForm2Data(values)
      setFormState(formStates[2])
      scrollUp()
    },
    validationSchema: Yup.object().shape({}),
    initialValues: { ...form2Data },
    backAction: () => {
      setFormState(formStates[0])
      scrollUp()
    },
  }

  const experienceForm = {
    formFields: [
      {
        heading: "Previous Trading Experience",
        fields: [
          {
            name: "trading_experience_traded_cfds_or_fx_before",
            type: "RADIO",
            label: "Have you traded CFDs or margined FX using a demo account",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
          },
          {
            name: "paragraph",
            type: "HTML_DATA",
            text:
              "<p>What, if any, products have you traded in a live environment (where you made the final trade decision) in the last 12 months?</p>",
          },
          {
            name: "trading_experience_forex",
            label: "Foreign Exchange (spot, forward or bullion)",
            type: "DROPDOWN",
            options: [
              { value: "", text: "Select Foreign Exchange" },
              { value: "never", text: "Never" },
              { value: "Rarely (Once a Month)", text: "Rarely (Once a Month)" },
              {
                value: "Sometimes(> 3 times a month)",
                text: "Sometimes(> 3 times a month)",
              },
              {
                value: "Often(> 10 times a month)",
                text: "Often(> 10 times a month)",
              },
            ],
            class: "col-sm-12",
            required: true,
          },
          {
            name: "trading_experience_futures_cfds_spreadbets",
            label: "Futures, CFDs or Spreadbets",
            type: "DROPDOWN",
            options: [
              { value: "", text: "Select Futures, CFDs pr Spreadbets" },
              { value: "never", text: "Never" },
              { value: "Rarely (Once a Month)", text: "Rarely (Once a Month)" },
              {
                value: "Sometimes(> 3 times a month)",
                text: "Sometimes(> 3 times a month)",
              },
              {
                value: "Often(> 10 times a month)",
                text: "Often(> 10 times a month)",
              },
            ],
            class: "col-sm-12",
            required: true,
          },
          {
            name: "trading_experience_shares_bonds",
            label: "Shares or Bonds",
            type: "DROPDOWN",
            options: [
              { value: "", text: "Select Shares or Bonds" },
              { value: "never", text: "Never" },
              { value: "Rarely (Once a Month)", text: "Rarely (Once a Month)" },
              {
                value: "Sometimes(> 3 times a month)",
                text: "Sometimes(> 3 times a month)",
              },
              {
                value: "Often(> 10 times a month)",
                text: "Often(> 10 times a month)",
              },
            ],
            class: "col-sm-12",
            required: true,
          },
        ],
      },
    ],
    submitHandler: values => {
      setForm3Data(values)
      setFormState(formStates[3])
      scrollUp()
    },
    validationSchema: Yup.object().shape({}),
    initialValues: {
      ...form3Data,
    },
    backAction: () => {
      setFormState(formStates[1])
      scrollUp()
    },
  }

  const acknowledgmentForm = {
    formFields: [
      {
        heading: "Market Knowledge and Awareness",
        fields: [
          {
            name: "market_knowledge_directors_understand_risk_of_trading",
            type: "RADIO",
            label:
              "Do the directors and/or principal contact person understand the nature and risk of trading margined/leveraged products?",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            class: "col-sm-12",
            labelBold: true,
            radioButtonInline: true,
          },
          {
            name: "market_knowledge_understand_you_can_lost_initial_deposit",
            type: "RADIO",
            label:
              "Do the directors and/or principal contact person understand that you can lose more than your initial deposit or margin payment?",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            class: "col-sm-12",
            labelBold: true,
            radioButtonInline: true,
          },
          {
            name: "market_knowledge_must_maintain_minimum_level_margin",
            type: "RADIO",
            label:
              "Are you aware that the Company must maintain a minimum level of margin in the account to cover any margin requirements ?",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            class: "col-sm-12",
            labelBold: true,
            radioButtonInline: true,
          },
          {
            name: "market_knowledge_understand_counterpart_position_with_us",
            type: "RADIO",
            label:
              "Do the directors and/or principal contact person understand that we are the Company’s counterparty and that your positions are taken not with an exchange?",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            class: "col-sm-12",
            labelBold: true,
            radioButtonInline: true,
          },
          {
            name: "market_knowledge_understand_volatile_market_may_impact",
            type: "RADIO",
            label:
              "Do the directors and/or principal contact understand that volatile market conditions may impact our ability to generate prices or to execute your orders?",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            class: "col-sm-12",
            labelBold: true,
            radioButtonInline: true,
          },
          {
            name: "market_knowledge_company_investment_objective",
            type: "RADIO",
            label: "What are the Company’s investment objectives",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            class: "col-sm-12",
            labelBold: true,
            radioButtonInline: true,
          },
        ],
      },
      {
        heading: "Client Agreement",
        fields: [
          {
            type: "HTML_DATA",
            text: "<strong>Please tick to confirm</strong> <br/><br/><br/>",
          },
          {
            name: "we_have_read_terms",
            type: "CHECKBOX",
            label:
              "We have read, understood and accept in full the Client Agreement (including Terms and Conditions, Order Execution Policy and Risk Warning Notice) (as amended from time to time).",
            class: "col-sm-12",
          },
          {
            name: "we_understand_and_accept",
            type: "CHECKBOX",
            label:
              "We understand and accept that our orders will be executed in accordance with the Order Execution Policy (as amended from time to time).",
            class: "col-sm-12",
          },
          {
            type: "HTML_DATA",
            text:
              "<strong>By submitting this application form I confirm that</strong> <br/><br/>",
          },
          {
            name: "we_have_access_to_internet",
            type: "CHECKBOX",
            label:
              "We have access to the internet and consent to Accuindex providing us with information about changes to the Client Agreement and/or Order Execution Policy by posting such information on its website www.accuindex.com",
            class: "col-sm-12",
          },
          {
            name: "we_under_stand_that_cfds",
            type: "CHECKBOX",
            label:
              "We understand that CFDs and margined FX are leveraged products and are sure they are suitable for the Company; we have been advised of our ability to seek independent financial advice",
            class: "col-sm-12",
          },
          {
            name: "we_confirm_information",
            type: "CHECKBOX",
            label:
              "We confirm that the information represented here is and accurate and not misleading in any respect. We agree to inform Accuindex promptly in writing should any of the information provided here cease to be correct",
            class: "col-sm-12",
          },
        ],
      },
    ],
    submitHandler: values => {
      setForm4Data(values)
      actualSubmit(values)
      // scrollUp();
    },
    validationSchema: Yup.object().shape({
      market_knowledge_directors_understand_risk_of_trading: Yup.string().required(
        "Required"
      ),
      market_knowledge_understand_you_can_lost_initial_deposit: Yup.string().required(
        "Required"
      ),
      market_knowledge_must_maintain_minimum_level_margin: Yup.string().required(
        "Required"
      ),
      market_knowledge_understand_counterpart_position_with_us: Yup.string().required(
        "Required"
      ),
      market_knowledge_understand_volatile_market_may_impact: Yup.string().required(
        "Required"
      ),
      market_knowledge_company_investment_objective: Yup.string().required(
        "Required"
      ),

      we_have_read_terms: Yup.bool().oneOf([true], "Must be checked"),
      we_understand_and_accept: Yup.bool().oneOf([true], "Must be checked"),
      we_have_access_to_internet: Yup.bool().oneOf([true], "Must be checked"),
      we_under_stand_that_cfds: Yup.bool().oneOf([true], "Must be checked"),
      we_confirm_information: Yup.bool().oneOf([true], "Must be checked"),
    }),
    initialValues: {
      we_have_read_terms: false,
      we_understand_and_accept: false,
      we_have_access_to_internet: false,
      we_under_stand_that_cfds: false,
      we_confirm_information: false,
      ...form4Data,
    },
    backAction: () => {
      setFormState(formStates[2])
      scrollUp()
    },
  }

  const actualSubmit = async values => {
    const allValues = {
      ...form1Data,
      ...form2Data,
      ...form3Data,
      ...form4Data,
      ...values,
    }
    const formData = new FormData()
    Object.keys(allValues).forEach(function (key) {
      formData.append(getLabelCorporateAccount(key), allValues[key])
    })
    try {
      console.log("submitting form......")
      await corporateApplicationSubmit(formData)
      console.log("form submitted......")
    } catch (error) {
      console.log("error submitting form ", error)
    }

    return
  }

  return (
    <>
      <section
        className={"job-contents p-top-20 p-bottom-65  live-account-form-row"}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 live-account-form">
              <div className="stepper-tabs" id="stepper-tabs-header">
                <ul
                  className="nav justify-content-around"
                  id="tab6"
                  role="tablist"
                >
                  {formStates.map((obj, index) => (
                    <li
                      className={
                        obj === formState ? "nav-item active" : "nav-item"
                      }
                    >
                      <a className="nav-link">
                        {index !== 0 ? (
                          <i class="las la-chevron-right mr-3"></i>
                        ) : (
                          ""
                        )}
                        {index + 1}. {obj}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                {formState === formStates[0] && (
                  <FormIkHandler
                    // title='Log in'
                    initialValues={personalForm.initialValues}
                    validationSchema={personalForm.validationSchema}
                    formFields={personalForm.formFields}
                    submitAction={personalForm.submitHandler}
                    buttonLabel="Continue"
                    submitStyle="continue-btn"
                    backButtonLabel="Back"
                    hideBackButton={true}
                  />
                )}
                {formState === formStates[1] && (
                  <FormIkHandler
                    // title='Log in'
                    initialValues={financialForm.initialValues}
                    validationSchema={financialForm.validationSchema}
                    formFields={financialForm.formFields}
                    submitAction={financialForm.submitHandler}
                    buttonLabel="Continue"
                    submitStyle="continue-btn"
                    backAction={financialForm.backAction}
                  />
                )}
                {formState === formStates[2] && (
                  <FormIkHandler
                    // title='Log in'
                    initialValues={experienceForm.initialValues}
                    validationSchema={experienceForm.validationSchema}
                    formFields={experienceForm.formFields}
                    submitAction={experienceForm.submitHandler}
                    buttonLabel="Continue"
                    submitStyle="continue-btn"
                    backAction={experienceForm.backAction}
                  />
                )}
                {formState === formStates[3] && (
                  <FormIkHandler
                    // title='Log in'
                    initialValues={acknowledgmentForm.initialValues}
                    validationSchema={acknowledgmentForm.validationSchema}
                    formFields={acknowledgmentForm.formFields}
                    submitAction={acknowledgmentForm.submitHandler}
                    buttonLabel="Submit"
                    submitStyle="continue-btn"
                    backAction={acknowledgmentForm.backAction}
                  />
                )}
              </div>
            </div>
          </div>
          {/* {children} */}
        </div>
      </section>
    </>
  )
}

export default CorporateAccountForm
