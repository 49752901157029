import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  query EconomicCalenderQuery {
    allPrismicEconomicCalender {
      nodes {
        data {
          banner_image {
            url
          }
          page_description {
            text
            type
            spans {
              end
              start
              type
            }
          }
          page_description1 {
            text
            type
            spans {
              end
              start
              type
            }
          }
          page_heading {
            text
          }
        }
      }
    }
  }
`

const dataResolver = (response, lang) => {
  const { allPrismicEconomicCalender } = response
  return allPrismicEconomicCalender.nodes[0].data
}

const useData = lang => {
  const response = useStaticQuery(query)
  return dataResolver(response, lang)
}

export default useData
