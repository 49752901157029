import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  query CorporateAccountQuery {
    allPrismicBasicContentPage(filter: { uid: { eq: "corporate-account" } }) {
      nodes {
        uid
        id
        data {
          page_under
          banner_image {
            url
          }
          banner_title {
            text
          }
          page_description {
            type
            text
            spans {
              end
              start
              type
              data {
                link_type
                url
              }
            }
          }
          page_heading {
            text
          }
          show_breadcrumb
          show_buttons
          show_stats
          show_subscription_panel
          show_risk_heading
          body {
            items {
              field_file {
                url
                link_type
                kind
              }
              field_text {
                text
                type
              }
            }
            slice_type
          }
          stats_replacement_text {
            text
          }
        }
      }
    }
  }
`

const dataResolver = (response, lang) => {
  const { allPrismicBasicContentPage } = response
  return allPrismicBasicContentPage.nodes[0].data
}

const useData = lang => {
  const response = useStaticQuery(query)
  return dataResolver(response, lang)
}

export default useData
