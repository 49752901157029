import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
query HeaderQuery {
  allPrismicHeader {
    nodes {
      data {
        academy_icon {
          url
        }
        academy_label {
          text
        }
        client_portal_icon {
          url
        }
        client_portal_label {
          text
        }
        demo_account_icon {
          url
        }
        demo_account_label {
          text
        }
        live_account_icon {
          url
        }
        live_account_label {
          text
        }
        logo_image {
          url
        }
        body {
          ... on PrismicHeaderBodyMenues {
            primary {
              menu_name {
                text
              }
              menu_link {
                text
              }
            }
            items {
              sub_menu_link {
                text
              }
              sub_menu_name {
                text
              }
              open_external
            }
          }
        }
        academy_link {
          text
        }
        client_portal_link {
          text
        }
        demo_account_link {
          text
        }
        live_account_link {
          text
        }
      }
      dataRaw
    }
  }
}


`
const dataResolver = (response, lang) => {
  const { allPrismicHeader } = response
  return allPrismicHeader.nodes[0]
  // return allPrismicHeaderSection.nodes.filter(x => x.lang === lang)[0].data
}

const useData = lang => {
  const response = useStaticQuery(query)
  return dataResolver(response, lang)
}

export default useData
