import React from "react"
import { Field } from "formik"

const RadioButton = props => {
  const { label, labelBold, name, options, ...rest } = props
  return (
    <div>
      <label style={{ display: "block" }} htmlFor={name}>
        {labelBold === true && <strong>{label}</strong>}
        {labelBold !== true && <>{label}</>}
      </label>

      <Field name={name} {...rest}>
        {({ field }) => {
          return options.map(option => {
            return (
              <div key={option.text} className="radio-btn-options">
                <div
                  style={{
                    display: "inline-block",
                    width: "25px",
                    verticalAlign: "top",
                  }}
                >
                  <input
                    type="radio"
                    id={option.id}
                    {...field}
                    value={option.value}
                    checked={field.value === option.value}
                  />
                </div>

                <div className="col-sm-10" style={{ display: "inline-block" }}>
                  <label htmlFor={option.id}>{option.text}</label>
                </div>
              </div>
            )
          })
        }}
      </Field>
    </div>
  )
}

export default RadioButton
