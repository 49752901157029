import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  query FooterSectionQuery {
    allPrismicFooterSection {
      nodes {
        data {
          body {
            primary {
              menu_label {
                text
              }
            }
            items {
              open_external
              sub_menu_label {
                text
              }
              sub_menu_link {
                text
              }
            }
          }
          mian_content {
            spans {
              type
              start
              end
              data {
                url
                link_type
              }
            }
            text
            type
          }
          office_heading_label {
            text
          }
          offices_box {
            location_description {
              spans {
                data {
                  link_type
                  url
                }
                end
                start
                type
              }
              text
              type
            }
            location_icon {
              url
            }
            location_title {
              text
            }
          }
          overlay_text {
            spans {
              data {
                link_type
                url
              }
              end
              start
              type
            }
            text
            type
          }
          reserved_label {
            text
          }
          social_icons_box {
            icon_class {
              text
            }
            icon_link {
              text
            }
          }
        }
      }
    }
  }
`
const dataResolver = (response, lang) => {
  const { allPrismicFooterSection } = response
  return allPrismicFooterSection.nodes[0].data
}

const useData = lang => {
  const response = useStaticQuery(query)
  return dataResolver(response, lang)
}

export default useData
