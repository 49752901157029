import React, { useState, useEffect } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

import FormIkHandler from "./formik-handler"
import { liveApplicationSubmit } from "../../actions"
import { COUNTRIES, getLabelLiveAccount } from "../../constants"
const LiveAccountForm = () => {
  const [form1Data, setForm1Data] = useState({})
  const [form2Data, setForm2Data] = useState({})
  const [form3Data, setForm3Data] = useState({})
  const [form4Data, setForm4Data] = useState({})

  const personalForm = {
    formFields: [
      {
        heading: "Account Information",
        fields: [
          {
            name: "Account Information: Account Type",
            type: "RADIO",
            label: "What account type do you require?",
            options: [
              { value: "standard", text: "Standard" },
              { value: "ecn", text: "ECN" },
            ],
            radioButtonInline: true,
          },
          {
            name: "Account Information: Account Currency",
            type: "RADIO",
            label: "Please select your account currency:",
            options: [
              { value: "usd", text: "USD" },
              { value: "eur", text: "EUR" },
              { value: "gbp", text: "GBP" },
            ],
            radioButtonInline: true,
          },
          {
            name:
              "Account Information: Will any other person trade on your behalf?",
            type: "RADIO",
            label: "Will any other person trade on your behalf?",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            radioButtonInline: true,
          },
          {
            name: "Account Information: What are your investment objectives?",
            type: "RADIO",
            label: "What are your investment objectives?",
            options: [
              { value: "speculation", text: "Speculation" },
              {
                value: "hedge against other investments",
                text: "Hedge against other investments",
              },
            ],
            radioButtonInline: true,
          },
          {
            name: "Account Information: How did you hear about us?",
            label: "How did you hear about us?",
            type: "DROPDOWN",
            options: [
              { value: "seminar", text: "Seminar" },
              { value: "online ad", text: "Online Ad" },
              { value: "magazine", text: "Magazine" },
              { value: "search engine", text: "Search Engine" },
              { value: "newspaper", text: "Newspaper" },
              { value: "introducer", text: "Introducer" },
              { value: "friend", text: "Friend" },
              { value: "other", text: "Other" },
            ],
            class: "col-sm-12",
          },
        ],
      },
      {
        heading: "Personal Detail",
        fields: [
          {
            name: "Personal Detail: Title",
            label: "Title",
            required: true,
            type: "DROPDOWN",
            options: [
              { value: "mr", text: "Mr" },
              { value: "ms", text: "Ms" },
            ],
            required: true,
          },
          {
            name: "Personal Detail: First Name",
            type: "TEXT",
            label: "First Name",
            required: true,
          },
          {
            name: "Personal Detail: Middle Name",
            type: "TEXT",
            label: "Middle name (s)",
          },
          {
            name: "Personal Detail: Surname",
            type: "TEXT",
            label: "Surname",
            required: true,
          },
          {
            name: "gender",
            type: "RADIO",
            label: "Gender",
            options: [
              { value: "male", text: "Male" },
              { value: "female", text: "Female" },
            ],
            required: true,
            radioButtonInline: true,
          },
          {
            name: "Personal Detail: Date of Birth",
            type: "DATE",
            label: "Date of Birth",
            required: true,
          },
          {
            name: "Personal Detail: Passport/ID card number",
            type: "TEXT",
            label: "Passport/ID card number",
          },
        ],
      },
      {
        heading: "Personal Verification",
        fields: [
          {
            name: "file:passport_copy",
            label: "Please upload a colored copy of your most recent Passport",
            required: true,
            type: "FILE",
            placeholder:
              "File format must be JPEG, PNG or PDF, Minimal resolution 300dpi",
            class: "col-sm-8 custom-upload",
            required: true,
          },
        ],
      },
      {
        heading: "Address Details",
        fields: [
          {
            name: "Address Details: House/flat name or number",
            type: "TEXT",
            label: "House/flat name or number",
          },
          {
            name: "Address Details: Street Name",
            type: "TEXT",
            label: "Street Name",
            required: true,
            required: true,
          },
          {
            name: "Address Details: Town",
            type: "TEXT",
            label: "Town",
          },
          {
            name: "Address Details: City",
            type: "TEXT",
            label: "City",
            required: false,
          },
          {
            name: "Address Details: Postcode",
            type: "TEXT",
            label: "Postcode",
          },
          {
            name: "Address Details: Country",
            type: "DROPDOWN",
            label: "Country",
            required: true,
            required: true,
            options: COUNTRIES,
          },
          {
            name: "Address Details: Mobile",
            type: "TEXT",
            label: "Mobile",
          },
          {
            name: "Address Details: Landline Telephone",
            type: "TEXT",
            label: "Landline Telephone",
          },
          {
            name:
              "Address Details: Is your mailing address the same as your postal address",
            type: "RADIO",
            label: "Is your mailing address the same as your postal address",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            radioButtonInline: true,
          },
        ],
      },
      {
        heading: "Address Verification",
        fields: [
          {
            name: "file:address_verification",
            label:
              "Please upload a colored copy of a recent utility bill or bank statement for address verification Valid document must contain",
            required: true,
            type: "FILE",
            placeholder:
              "File format must be JPEG, PNG or PDF, Minimal resolution 300dpi",
            class: "col-sm-8",
            required: true,
          },
        ],
      },
    ],
    submitHandler: values => {
      setForm1Data(values)
      setFormState(formStates[1])
      scrollUp()
    },
    validationSchema: Yup.object().shape({
      "Personal Detail: First Name": Yup.string().required(
        "This field is Required"
      ),
      "Personal Detail: Surname": Yup.string().required("Required"),
      "Personal Detail: Gender": Yup.string().required("Required"),
      "Personal Detail: Date of Birth": Yup.string().required("Required"),
      "file:passport_copy": Yup.string().required("Required"),
      "Address Details: Street Name": Yup.string().required("Required"),
      // "Address Details: Country": Yup.string()
      //     .required('Required'),
      "file:address_verification": Yup.string().required("Required"),
      gender: Yup.string().required("Required"),
    }),
    initialValues: {
      // "Personal Detail: First Name": 'aadil'
      "Personal Detail: Gender": "male",
      ...form1Data,
    },
  }

  const financialForm = {
    formFields: [
      {
        heading: "Employment & Financial Information",
        fields: [
          {
            name: "Account Information: Employment Status",
            type: "DROPDOWN",
            label: "Employment Status",
            class: "label-margin",
            options: [
              { value: "", text: "Select Employment Status" },
              { value: "employed", text: "Employed" },
              { value: "self employed", text: "Self Employed" },
              { value: "retired", text: "Retired" },
              { value: "student", text: "Student" },
              { value: "unemployed", text: "Unemployed" },
            ],
          },
          {
            name:
              "Account Information: Approximate annual income from all sources (USD or equivalent)",
            type: "TEXT",
            label:
              "Approximate annual income from all sources (USD or equivalent)",
          },
          {
            name:
              "Account Information: Approximate value of savings and investments (excluding private residence) (USD or equivalent)",
            type: "TEXT",
            label:
              "Approximate value of savings and investments (excluding private residence) (USD or equivalent)",
          },
          {
            name:
              "Account Information: Approximate value of savings and investments (excluding private residence) (USD or equivalent)",
            type: "TEXT",
            label:
              "Approximate value of savings and investments (excluding private residence) (USD or equivalent)",
          },
          {
            type: "HTML_DATA",
            text:
              "<p>Please indicate the source(s) of your funds for trading: (please tick one or more)</p>",
          },
          {
            name:
              "Account Information: Source of Funds: Employment/self employmen",
            type: "CHECKBOX",
            label: "Employment/ self employmen",
            class: "col-sm-3",
          },
          {
            name:
              "Account Information: Source of Funds: Temporary or part time employment",
            type: "CHECKBOX",
            label: "Temporary or part time employment",
            class: "col-sm-3",
          },
          {
            name: "Account Information: Source of Funds: Inheritance",
            type: "CHECKBOX",
            label: "Inheritance",
            class: "col-sm-3",
          },
          {
            name: "Account Information: Source of Funds: Family/partner income",
            type: "CHECKBOX",
            label: "Family/partner income",
            class: "col-sm-3",
          },
          {
            name: "Account Information: Source of Funds: Savings",
            type: "CHECKBOX",
            label: "Savings",
            class: "col-sm-3",
          },
          {
            name: "Account Information: Source of Funds: Pension",
            type: "CHECKBOX",
            label: "Pension",
            class: "col-sm-3",
          },
          {
            name: "Account Information: Source of Funds: Property income",
            type: "CHECKBOX",
            label: "Property income",
            class: "col-sm-3",
          },
          {
            name: "Account Information: Source of Funds: Investments",
            type: "CHECKBOX",
            label: "Investments",
            class: "col-sm-3",
          },
          {
            name: "Account Information: Source of Funds: Benefits or borrowing",
            type: "CHECKBOX",
            label: "Benefits or borrowing",
            class: "col-sm-3",
          },
          {
            name: "Account Information: Source of Funds: Other",
            type: "CHECKBOX",
            label: "Other",
            class: "col-sm-3",
          },
          {
            name:
              "Account Information: Approximate value of savings and investments (excluding private residence) (USD or equivalent)",
            type: "TEXT",
            label:
              "Approximate value of savings and investments (excluding private residence) (USD or equivalent)",
            class: "col-md-8",
          },
        ],
      },
      {
        heading: "Bank Details",
        fields: [
          {
            name: "Account Information: Bank Name",
            type: "TEXT",
            label: "Bank name",
          },
          {
            name: "Account Information: Account Number",
            type: "TEXT",
            label: "Account Number",
          },
          {
            name: "Account Information: Account Holder Name",
            type: "TEXT",
            label: "Account Holder Name",
          },
        ],
      },
    ],
    submitHandler: values => {
      setForm2Data(values)
      setFormState(formStates[2])
      scrollUp()
    },
    validationSchema: Yup.object().shape({}),
    initialValues: {},
    backAction: () => {
      setFormState(formStates[0])
      scrollUp()
    },
  }

  const experienceForm = {
    formFields: [
      {
        heading: "Politcally Exposed Person (PEP)",
        fields: [
          {
            name: "heading_only",
            type: "HTML_DATA",
            label: "",
            text: `
                                <p>A Politically Exposed Person is an individual who is or has at any time been entrusted with prominent public functions and an immediate family member, or known close associate of such a person.</p>
                                <p><b>A - Prominent public functions include:</b></p>
                                <ul class="bullet--list2">
                                    <li class="bullet_list">Heads of State, heads of government, ministers and deputy or assistant ministers;</li>
                                    <li class="bullet_list">Members of parliaments;</li>
                                    <li class="bullet_list">Members of supreme courts, of constitutional courts or of other high-level judicial bodies whose decisions are not generally subject to further appeal, except in exceptional circumstances;</li>
                                    <li class="bullet_list">Members of courts of auditors or of the boards of central banks;</li>
                                    <li class="bullet_list">Ambassadors, Chargés d'affaires and hihg ranking officers in the armed forces; and</li>
                                    <li class="bullet_list">Members of the administrative, management or supervisory boards of State-owned enterprises</li>
                                </ul><!-- ends: .bullet--list2 -->
                                <p>Please note that the categories above do not include middle-ranking or more junior officials.</p>
                                <p><b>B - Immediate family members of such persons as set out under part A are also Politically Exposed Persons, this includes:</b></p>
                                <br>
                                <ul class="bullet--list2">
                                    <li class="bullet_list">A spouse;</li>
                                    <li class="bullet_list">A partner (including a person who is considered by his national law as equivalent to a spouse);</li>
                                    <li class="bullet_list">
                                        Children and their spouses or partners; and parents.
                                        Close associates of such persons as set out under part A are also Politically Exposed Persons, this includes:
                                    </li>
                                    <li class="bullet_list">Any individual who is known to have joint beneficial ownership of a legal entity or legal arrangement, or any other close business relations, with a person as stated in part A; and</li>
                                    <li class="bullet_list">
                                        Any individual whi has sole beneficial ownership of a legal entity or legal arrangement which is known to have been set up for the benefit of a person as stated in part A.
                                    </li>
                                </ul><!-- ends: .bullet--list2 -->
                            `,
          },
          {
            name: "accept_not_politically_exposed",
            type: "CHECKBOX",
            label:
              "I have read and understood the definition provided and can confirm I am NOT a politically exposed person",
            value: true,
            class: "col-sm-12",
          },
          {
            name: "accept_politically_exposed",
            type: "CHECKBOX",
            label:
              "I have read and understood the definition provided and can confirm I am a politically exposed person",
            value: false,
            class: "col-sm-12",
          },
        ],
      },
      ,
      {
        heading: "Trading Experience",
        fields: [
          {
            name: "Have you traded CFDs or margined FX using a demo account",
            type: "RADIO",
            label: "Have you traded CFDs or margined FX using a demo account",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
          },
          {
            name: "paragraph",
            type: "HTML_DATA",
            text:
              "<p>What, if any, products have you traded in a live environment (where you made the final trade decision) in the last 12 months?</p>",
          },
          {
            name: "Trading Experience: Forex",
            label: "FOREX",
            type: "DROPDOWN",
            options: [
              { value: "", text: "Select Forex" },
              { value: "never", text: "Never" },
              { value: "Rarely (Once a Month)", text: "Rarely (Once a Month)" },
              {
                value: "Sometimes(> 3 times a month)",
                text: "Sometimes(> 3 times a month)",
              },
              {
                value: "Often(> 10 times a month)",
                text: "Often(> 10 times a month)",
              },
            ],
            class: "col-md-6",
            required: true,
          },
          {
            name: "Trading Experience: Commodities",
            label: "Commodities",
            type: "DROPDOWN",
            options: [
              { value: "", text: "Select Commodities" },
              { value: "never", text: "Never" },
              { value: "Rarely (Once a Month)", text: "Rarely (Once a Month)" },
              {
                value: "Sometimes(> 3 times a month)",
                text: "Sometimes(> 3 times a month)",
              },
              {
                value: "Often(> 10 times a month)",
                text: "Often(> 10 times a month)",
              },
            ],
            class: "col-md-6",
            required: true,
          },
          {
            name: "Trading Experience: CFDs",
            label: "CFDS",
            type: "DROPDOWN",
            options: [
              { value: "", text: "Select CDS" },
              { value: "never", text: "Never" },
              { value: "Rarely (Once a Month)", text: "Rarely (Once a Month)" },
              {
                value: "Sometimes(> 3 times a month)",
                text: "Sometimes(> 3 times a month)",
              },
              {
                value: "Often(> 10 times a month)",
                text: "Often(> 10 times a month)",
              },
            ],
            class: "col-md-6",
            required: true,
          },
          {
            name: "Trading Experience: Shares",
            label: "Shares",
            type: "DROPDOWN",
            options: [
              { value: "", text: "Select Shares" },
              { value: "never", text: "Never" },
              { value: "Rarely (Once a Month)", text: "Rarely (Once a Month)" },
              {
                value: "Sometimes(> 3 times a month)",
                text: "Sometimes(> 3 times a month)",
              },
              {
                value: "Often(> 10 times a month)",
                text: "Often(> 10 times a month)",
              },
            ],
            class: "col-md-6",
            required: true,
          },
        ],
      },
    ],
    submitHandler: values => {
      setForm3Data(values)
      setFormState(formStates[3])
      scrollUp()
    },
    validationSchema: Yup.object().shape({}),
    initialValues: {
      ...form3Data,
    },
    backAction: () => {
      setFormState(formStates[1])
      scrollUp()
    },
  }

  const acknowledgmentForm = {
    formFields: [
      {
        heading: "Market Knowledge and Awareness",
        fields: [
          {
            type: "HTML_DATA",
            text:
              "<strong>Do you have any current/former employment, professional experience or qualifications which would assist your understanding of CFDs and FX and the risks of trading leveraged products?</strong><br/><br/>",
          },
          {
            name: "market_knowledge_and_awarness",
            type: "RADIO",
            label: " ",
            options: [
              {
                value: "atleast_2_years",
                text:
                  "Yes, I have worked for at least 2 years in a directly relevant role within the financial services industry",
              },
              {
                value: "good_understanding",
                text:
                  "Yes, I have a good understanding of the risks of leveraged products as I have attended educational seminar(s) or completed other professional training relating to CFDs and/or FX trading",
              },
              {
                value: "have_diploma_higher_level_Acedemic_qualification",
                text:
                  "Yes, I hold a diploma or higher level academic qualification in a relevant field",
              },
              {
                value: "no_i_dont_have",
                text:
                  "No, I do not have any relevant current or previous employment or qualifications",
              },
            ],
            class: "col-sm-12",
            labelBold: true,
          },
          {
            name:
              "Market Knowledge and Awarenes: Do you understand the nature and risk of trading margined/leveraged products?",
            type: "RADIO",
            label:
              "Do you understand the nature and risk of trading margined/leveraged products?",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            class: "col-sm-12",
            labelBold: true,
          },
          {
            name:
              "Market Knowledge and Awarenes: Do you understand that you can lose more than your initial deposit or margin payment?",
            type: "RADIO",
            label:
              "Do you understand that you can lose more than your initial deposit or margin payment?",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            labelBold: true,
            class: "col-sm-12",
          },
          {
            name:
              "Market Knowledge and Awarenes: Are you aware that you must maintain a minimum level of margin in your account to cover any margin requirements?",
            type: "RADIO",
            label:
              "Are you aware that you must maintain a minimum level of margin in your account to cover any margin requirements?",
            labelBold: true,
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            class: "col-sm-12",
            labelBold: true,
          },
          {
            name:
              "Market Knowledge and Awarenes: Do you understand that we are your counterparty and that your positions are taken with us and not with an exchange?",
            type: "RADIO",
            label:
              "Do you understand that we are your counterparty and that your positions are taken with us and not with an exchange?",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            class: "col-sm-12",
            labelBold: true,
          },
          {
            name:
              "Market Knowledge and Awarenes: Do you understand that volatile market conditions may impact our ability to generate prices or to execute your orders?",
            type: "RADIO",
            label:
              "Do you understand that volatile market conditions may impact our ability to generate prices or to execute your orders?",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            class: "col-sm-12",
            labelBold: true,
          },
        ],
      },
      {
        heading: "Speculation FATCA - The Foreign Account Tax Compilance Act",
        fields: [
          {
            type: "HTML_DATA",
            text:
              "FATCA is a US law that means UK financial institutions must collect information on US tax residents using foreign accounts. <br/><br/>",
          },
          {
            name:
              "Speculation FATCA: Are you a citizen of the United States of America?",
            type: "RADIO",
            label: "Are you a citizen of the United States of America?",
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
            class: "col-sm-12",
            labelBold: true,
          },
          {
            name:
              "Speculation FATCA: Tax identification number (if applicable)",
            type: "TEXT",
            label: "Tax identification number (if applicable)",
          },
        ],
      },
      {
        heading: "Client Agreement",
        fields: [
          {
            type: "HTML_DATA",
            text: "<strong>Please tick to confirm</strong> <br/><br/><br/>",
          },
          {
            name: "i_have_read_terms",
            type: "CHECKBOX",
            label:
              "I have read, understood and accept in full the Client Agreement (including Terms and Conditions, Best Execution Policy and Risk Warning Notice(as amended from time to time).",
            class: "col-sm-12",
          },
          {
            name: "i_understand_and_accept",
            type: "CHECKBOX",
            label:
              "I understand and accept that my orders will be executed in accordance with the Best Execution Policy (as amended from time to time).",
            class: "col-sm-12",
          },
          {
            type: "HTML_DATA",
            text:
              "<strong>By submitting this application form I confirm that</strong> <br/><br/>",
          },
          {
            name: "i_have_access_to_internet",
            type: "CHECKBOX",
            label:
              "I have access to the internet and consent to Accuindex providing me with information about changes to the Client Agreement and/or Best Execution Policy by posting such information on the Company website www.accuindex.com",
            class: "col-sm-12",
          },
          {
            name: "i_under_stand_that_cfds",
            type: "CHECKBOX",
            label:
              "I understand that CFDs and margined FX are leveraged products and am sure they are suitable for me; I have been advised of my ability to seek independent financial advice",
            class: "col-sm-12",
          },
          {
            name: "i_am_over_18_confirm",
            type: "CHECKBOX",
            label:
              "I am over 18 and confirm that the information represented here is true and accurate and not misleading in any respect, I agree to inform Accuindex promptly in writing should any of the information provided here cease to be correct.",
            class: "col-sm-12",
          },
        ],
      },
    ],
    submitHandler: values => {
      setForm4Data(values)
      actualSubmit(values)
      scrollUp()
    },
    validationSchema: Yup.object().shape({
      "Speculation FATCA: Are you a citizen of the United States of America?": Yup.string().required(),
      // "Speculation FATCA: Tax identification number (if applicable)": Yup.string().required('Required'),
      market_knowledge_and_awarness: Yup.string().required("Required"),

      i_have_read_terms: Yup.bool().oneOf([true], "Must be checked"),
      i_understand_and_accept: Yup.bool().oneOf([true], "Must be checked"),
      i_have_access_to_internet: Yup.bool().oneOf([true], "Must be checked"),
      i_under_stand_that_cfds: Yup.bool().oneOf([true], "Must be checked"),
      i_am_over_18_confirm: Yup.bool().oneOf([true], "Must be checked"),
    }),
    initialValues: {
      i_have_read_terms: false,
      i_understand_and_accept: false,
      i_have_access_to_internet: false,
      i_under_stand_that_cfds: false,
      i_am_over_18_confirm: false,
      ...form4Data,
    },
    backAction: () => {
      setFormState(formStates[2])
      scrollUp()
    },
  }

  const actualSubmit = async values => {
    const allFiles = {
      ...form1Data.files,
      ...form2Data.files,
      ...form3Data.files,
      ...form4Data.files,
      ...values,
    }
    const allValues = {
      ...form1Data,
      ...form2Data,
      ...form3Data,
      ...form4Data,
    }
    const formData = new FormData()
    Object.keys(allValues).forEach(function (key) {
      formData.append(getLabelLiveAccount(key), allValues[key])
    })
    // console.log('from objd f', allFiles.address_verification)

    Object.keys(allFiles).forEach(function (key, ind) {
      formData.append(`image-${key}`, allFiles[key])
    })
    try {
      console.log("submitting form......")
      await liveApplicationSubmit(formData)
      console.log("form submitted......")
    } catch (error) {
      console.log("error submitting form ", error)
    }

    return
  }

  const formStates = ["personal", "financial", "experience", "acknowledgment"]

  const [formState, setFormState] = useState(formStates[0])

  const scrollUp = () => {
    const element = document.getElementById("stepper-tabs-header")
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    })
    console.log("ELELEMENT", element)
  }

  return (
    <>
      <section
        className={"job-contents p-top-20 p-bottom-65  live-account-form-row"}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 live-account-form">
              <div className="stepper-tabs" id="stepper-tabs-header">
                <ul
                  className="nav justify-content-around"
                  id="tab6"
                  role="tablist"
                >
                  {formStates.map((obj, index) => (
                    <li
                      className={
                        obj === formState ? "nav-item active" : "nav-item"
                      }
                    >
                      <a className="nav-link">
                        {index !== 0 ? (
                          <i class="las la-chevron-right mr-3"></i>
                        ) : (
                          ""
                        )}
                        {index + 1}. {obj}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                {formState === formStates[0] && (
                  <FormIkHandler
                    // title='Log in'
                    initialValues={personalForm.initialValues}
                    validationSchema={personalForm.validationSchema}
                    formFields={personalForm.formFields}
                    submitAction={personalForm.submitHandler}
                    buttonLabel="Continue"
                    submitStyle="continue-btn"
                    backButtonLabel="Back"
                    hideBackButton={true}
                  />
                )}
                {formState === formStates[1] && (
                  <FormIkHandler
                    // title='Log in'
                    initialValues={financialForm.initialValues}
                    validationSchema={financialForm.validationSchema}
                    formFields={financialForm.formFields}
                    submitAction={financialForm.submitHandler}
                    buttonLabel="Continue"
                    submitStyle="continue-btn"
                    backAction={financialForm.backAction}
                  />
                )}
                {formState === formStates[2] && (
                  <FormIkHandler
                    // title='Log in'
                    initialValues={experienceForm.initialValues}
                    validationSchema={experienceForm.validationSchema}
                    formFields={experienceForm.formFields}
                    submitAction={experienceForm.submitHandler}
                    buttonLabel="Continue"
                    submitStyle="continue-btn"
                    backAction={experienceForm.backAction}
                  />
                )}
                {formState === formStates[3] && (
                  <FormIkHandler
                    // title='Log in'
                    initialValues={acknowledgmentForm.initialValues}
                    validationSchema={acknowledgmentForm.validationSchema}
                    formFields={acknowledgmentForm.formFields}
                    submitAction={acknowledgmentForm.submitHandler}
                    buttonLabel="Submit"
                    submitStyle="continue-btn"
                    backAction={acknowledgmentForm.backAction}
                  />
                )}
              </div>
            </div>
          </div>
          {/* {children} */}
        </div>
      </section>
    </>
  )
}

export default LiveAccountForm
