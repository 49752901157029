import React from "react"
// import { Link } from "gatsby"

import { Button } from "react-bootstrap"
import { SharedDataQuery } from "../../prismic/staticQueries"

const Banner = ({
  heading = [],
  show_breadcrumb = false,
  show_buttons = true,
  show_risk_heading = true,
  banner_image = {},
}) => {
  const { main_buttons = [], risk_heading } = SharedDataQuery()

  return (
    <>
      <section className="topBanner">
        <div
          className="bg_image_holder"
          style={{ backgroundImage: `url(${banner_image.url})`, opacity: 1 }}
        >
          <img src={banner_image.url} alt="banner" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumb_wrapper d-flex flex-column align-items-center">
                <h3 className="page_title">{heading[0].text}</h3>
                <div className="action-btns m-top-35">
                  {show_buttons === true && (
                    <ul className="d-flex" style={{ paddingBottom: "2rem" }}>
                      {main_buttons.map((button, index) => (
                        <li key={"list-key" + index}>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={button.button_link[0].text}
                            className="anchor"
                          >
                            <Button
                              style={{
                                backgroundColor: button.button_color,
                                color: "#fff",
                              }}
                            >
                              {button.button_value[0].text}
                            </Button>
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                  {show_risk_heading === true && (
                    <p style={{ color: "#fff" }}>{risk_heading[0].text}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Banner
