import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  query EducationAcademyQuery {
    allPrismicEducationAcademy {
      nodes {
        data {
          page_title {
            text
          }
          banner_image {
            url
          }
          body {
            items {
              rep_body {
                text
                type
                spans {
                  end
                  start
                  type
                }
                url
              }
              rep_title {
                text
              }
            }
            primary {
              section_image {
                url
              }
              section_title {
                text
              }
              section_description {
                spans {
                  end
                  start
                  type
                }
                text
                type
              }
            }
          }
          page_content {
            text
          }
          page_heading {
            text
            type
          }
        }
        lang
      }
    }
  }
`
const dataResolver = response => {
  const { allPrismicEducationAcademy } = response
  return allPrismicEducationAcademy.nodes[0].data
}

const useData = lang => {
  const response = useStaticQuery(query)
  return dataResolver(response)
}

export default useData
