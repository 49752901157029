import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  query SharedDataQuery {
    allPrismicSharedData {
      nodes {
        data {
          main_buttons {
            button_color
            button_link {
              text
            }
            button_value {
              text
            }
          }
          risk_heading {
            text
          }
          stats_heading {
            text
          }
          stats_items {
            stat_icon {
              text
            }
            stat_title {
              text
            }
            stat_value {
              text
            }
          }
          submit_button_lable {
            text
          }
          subscription_image {
            url
          }
          subscription_placeholder {
            text
          }
        }
      }
    }
  }
`
const dataResolver = (response, lang) => {
  const { allPrismicSharedData } = response
  return allPrismicSharedData.nodes[0].data
}

const useData = lang => {
  const response = useStaticQuery(query)
  return dataResolver(response, lang)
}

export default useData
